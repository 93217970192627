import {fetchGet} from '../../router/https'
// 走路挣金币
function walkMoney(data){
    return fetchGet('/mallapp/juhe/walkMoney', data)
}
// 首页参数
function signWalk(data){
    return fetchGet('/mallapp/juhe/signWalk', data)
}
// 领取了多少金币
function manyGold(data){
    return fetchGet('/mallapp/juhe/walkState', data)
}
export {
    walkMoney,
    signWalk,
    manyGold
}