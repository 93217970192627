<template>
  <div class="box">
    <van-nav-bar
      title="走路赚钱"
      left-arrow
      fixed
      placeholder
      @click-left="onClickLeft"
    />
    <div class="contents">
      <div class="outer5">
        <div class="main2">
          <img
            style="width: 30px; height: 30px"
            class="icon2"
            src="../../assets/img/Invitation/金币@2x.png"
          />
          <span class="word4" style="margin-bottom: 5px">{{ number }}</span>
          <van-icon name="arrow" color="white" />
          <!-- <span class="layer2"></span> -->
        </div>
      </div>
      <div class="text">
        <div class="stp">今日步数</div>
        <div class="steep">{{ steep }}</div>
        <div class="ratio">超过{{ ratio }}的用户</div>
      </div>
      <img class="img1" src="./img/leftYuan.png" alt="" />
      <img class="img2" src="./img/lou.png" />
      <img class="img3" src="./img/rightLou.png" />
      <img class="pic1" src="./img/rightCao.png" />
      <img class="pic2" referrerpolicy="no-referrer" src="./img/yuan.png" />
      <img class="img4" src="./img/ren.png" />
      <div class="bd2 flex-col"></div>
    </div>
    <div class="main">
      <div class="main1">
        <div class="bd4">
          <span
            class="txt1"
            :style="{ color: rightsteep.length >= 1 ? 'orange' : '' }"
            >+50</span
          >
          <span
            class="txt1"
            :style="{ color: rightsteep.length >= 2 ? 'orange' : '' }"
            >+80</span
          >
          <span
            class="txt1"
            :style="{ color: rightsteep.length >= 3 ? 'orange' : '' }"
            >+100</span
          >
          <span
            class="txt1"
            :style="{ color: rightsteep.length >= 4 ? 'orange' : '' }"
            >+150</span
          >
          <span
            class="txt1"
            :style="{ color: rightsteep.length >= 5 ? 'orange' : '' }"
            >+200</span
          >
        </div>
        <div class="bd5 flex-col">
          <!-- <div class="outer6" :style="{width: this.rightsteep.length* 55 + 'px'}"> -->
          <div class="outer6" :style="{ width: lineWidth }">
            <img
              :style="{ left: 56 + (item - 1) * 56 + 'px' }"
              v-for="(item, index) in rightsteep"
              :key="index"
              class="label3"
              src="../../assets/img/Invitation/完成备份@2x.png"
            />
          </div>
        </div>
        <div class="bd6 flex-row justify-between">
          <span class="txt3">5百步</span> <span class="txt4">3千步</span>
          <span class="txt5">5千步</span> <span class="info4">8千步</span>
          <span class="word7">1万步</span>
        </div>
        <!-- 一开始 -->
        <div v-if="nowGoldStatus" class="Ge-box">
          <div
            class="button"
            v-if="this.walkData.walkNow !== 0"
            @click="getGold"
          >
            <span class="word8">恭喜你已走完{{ this.walkData.walk }}步</span>
          </div>
          <div
            class="button flex-col"
            style="background: rgb(212, 209, 209)"
            v-else
          >
            <span class="word8"
              >你已走完{{ this.walkData.walkNow }}步, 继续加油吧~</span
            >
          </div>
        </div>

        <!-- 签到后 -->
        <div v-if="!nowGoldStatus" class="Ge-box">
          <div class="button1">
            <span>
              {{
                this.walkData.differenceStep > 0
                  ? '差' + this.walkData.differenceStep + '步'
                  : this.walkData.differenceStep
              }}
            </span>
            <span>
              {{
                this.walkData.nextStageGold > 0
                  ? '领' + this.walkData.nextStageGold + '金币'
                  : '今日已领完'
              }}
            </span>
          </div>
        </div>

        <div class="section8 flex-col"></div>
      </div>
      <div class="main2">
        <div class="text">
          <span class="word9">连续7天领好礼</span>
          <div class="outer7">
            <span class="txt6">连续参与</span> <span class="word10">7</span>
            <span class="word11">天并领取奖励可激活大奖</span>
          </div>
        </div>
        <div class="sign-desk flex-row justify-between">
          <!-- @click="mask(item.day, item.steep)" -->
          <div class="section2" v-for="(item, index) in souvenir" :key="index">
            <div
              :class="['mod1', 'flex-col', { active: index <= signDay - 1 }]"
            >
              <div
                :class="[
                  'layer3',
                  'flex-col',
                  { active2: index <= signDay - 1 },
                ]"
              >
                <span class="word12">第{{ item.day }}天</span>
              </div>
              <div class="layer4 flex-col">
                <div :class="{ none: item.day != 3 }">
                  <img
                    class="icon5"
                    src="../../assets/img/Invitation/金币@2x.png"
                  />
                </div>
                <div :class="{ imgnone: item.day == 3 }">
                  <img
                    class="icon4"
                    :src="index <= signDay - 1 ? signedSrc : singingSrc"
                  />
                </div>
              </div>
              <div class="layer5 flex-col">
                <span class="word13">{{ item.steep }}</span>
              </div>
            </div>
          </div>
          <div
            class="mod3 flex-col justify-between"
            :style="{ background: signDay == 7 ? '#0a56c4' : '' }"
            @click="maskGift()"
          >
            <div
              class="mod4 flex-col"
              :style="{ background: signDay == 7 ? '#9ec6f8' : '' }"
            >
              <span class="word20">第7天</span>
            </div>
            <div class="mod5">
              <!-- 礼包@2x -->
              <span class="info7" :style="{ color: signDay == 7 ? '#fff' : '' }"
                >神秘礼包</span
              >
              <img
                style="margin-top: -4px"
                class="img5"
                src="./img/libao.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mask" v-show="show">
      <div class="block">
        <div></div>
        <div class="mod3 flex-col">
          <div class="group1 flex-col">
            <div style="height: 14px"></div>
            <span class="word2">领取成功</span>
            <img class="pic6" v-show="onshow" src="./img/jinbi.png" />
            <img class="img4" v-show="!onshow" src="./img/zhengque.png" />
            <span v-show="onshow" class="txt3"
              >恭喜获得{{ this.signedData2.getGoldNum }}金币</span
            >
            <span v-show="!onshow" class="txt3"
              >恭喜获得{{ manyStep }}步数</span
            >
            <div class="layer2" v-if="getGoldWordTrue">
              <span v-if="signWalkFinished">
                <span class="word3">已连续领取</span>
                <span class="word4">{{ manyDays }}</span>
                <span class="info4">天，继续加油～</span>
              </span>
            </div>
            <div class="Ge-happy" @click="sht">
              <span>开心收下</span>
            </div>
          </div>
        </div>
        <div class="Ge-cancel">
          <van-icon color="white" name="close" size="25px" @click="padlock" />
        </div>
      </div>
      <div class="block2"></div>
    </div>
  </div>
</template>

<script>
// 完成备份@2x
import { walkMoney, signWalk } from '../../api/makeMoney'
export default {
  data() {
    return {
      show: false, //一进来就显示签到   现在一进来不显示
      rightsteep: [], //多少个点
      lineWidth: 0 + '%', // 这条线的宽度
      onshow: true,
      number: 0,
      steep: '0',
      ratio: '20%',
      souvenir: [
        {
          day: 1,
          steep: '500步',
        },
        {
          day: 2,
          steep: '600步',
        },
        {
          day: 3,
          steep: '金币奖励',
        },
        {
          day: 4,
          steep: '800步',
        },
        {
          day: 5,
          steep: '1000步',
        },
        {
          day: 6,
          steep: '1200步',
        },
      ],
      manyDays: '', //弹出显示多少天
      manyStep: '', //弹出显示多少步
      colorDay: '',
      getGoldWordTrue: true,
      walkData: {},
      signDay: null, //共签到天数
      signWalkFinished: true, //点击后里面的内容有什么不需要显示
      signedData: {},
      nowGoldStatus: false, //用户现在可以领取的金币 1可以领取 0没有金币领取
      goldManyNum: null, //用户这次领取的金币数
      signedData2: {},
      disAbledStatus: 0,
      signedSrc: require('./img/lanGou.png'),
      singingSrc: require('./img/gou.png'),
    }
  },
  created() {
    // this.$store.commit(
    //   'setSession',
    //   this.$route.query.session ||
    //     '$2a$10$azpLyrVeRGcxFl.gx8s96O65jSgrodmmPZSE4NMgqyLb5UHbSiWeC'
    // )
    this.$store.commit(
      'setSession',
      this.$route.query.session ||
        '$2a$10$By8liPgQcifKYh/Pfm.w9.ocdokhFxHaufIN1QiKwQpmb2zfdTBxq'
    )
    this.signWalk()
    this.manyDays = 2
    this.manyStep = 10
    if (this.manyDays == 3) {
      this.onshow = true
    } else {
      this.onshow = false
    }
  },
  mounted() {
    this.setRem()
    window.addEventListener('orientationchange', this.setRem)
    window.addEventListener('resize', this.setRem)
    this.$nextTick(() => {
      //   this.$refs.search.focus()
    })
  },
  methods: {
    setRem() {
      let screenWidth = document.documentElement.getBoundingClientRect().width
      document.documentElement.style.fontSize = screenWidth / 10 + 'px'
    },
    // 返回
    onClickLeft() {
      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage('up')
      }
      if (isAndroid) {
        window.android.close()
      }
    },
    // 首页数据    // 一进来就签到
    signWalk() {
      let params = {
        walk: this.$route.query.walk - 0 || 0,
      }
      // console.log(params, 'params');
      signWalk(params).then((res) => {
        if (res.data.code === 0) {
          // console.log(res, "res");
          this.walkData = res.data.data
          this.number = this.walkData.goldNum
          this.steep = this.walkData.walk
          this.signDay = res.data.data.signStep.continueDays
          // res.data.data.result = 1
          if (res.data.data.result == 1) {
            this.nowGoldStatus = false
            this.show = false
          } else {
            this.nowGoldStatus = true
            this.show = true
            this.getGold()
          }
          // 后端返回数据的处理
          this.walkData.walkNow = this.walkData.walk
          if (this.walkData.walkNow >= 500 && this.walkData.walkNow < 3000) {
            this.rightsteep = [1]
            this.lineWidth = 21 + '%'
          } else if (
            this.walkData.walkNow >= 3000 &&
            this.walkData.walkNow < 5000
          ) {
            this.rightsteep = [2, 1]
            this.lineWidth = 36 + '%'
          } else if (
            this.walkData.walkNow >= 5000 &&
            this.walkData.walkNow < 8000
          ) {
            this.rightsteep = [3, 2, 1]
            this.lineWidth = 53 + '%'
          } else if (
            this.walkData.walkNow >= 8000 &&
            this.walkData.walkNow < 10000
          ) {
            this.rightsteep = [4, 3, 2, 1]
            this.lineWidth = 69 + '%'
          } else if (this.walkData.walkNow >= 10000) {
            this.rightsteep = [5, 4, 3, 2, 1]
            this.lineWidth = 85 + '%'
          } else if (this.walkData.walkNow > 0 && this.walkData.walkNow < 500) {
            this.lineWidth = 0 + '%'
            this.rightsteep = []
          }
          // this.getGold()
        }
      })
    },
    // 中间的获取金币
    getGold() {
      this.signWalkFinished = false
      this.manyDays = 3
      if (this.manyDays == 3) {
        this.onshow = true
      } else {
        this.onshow = false
      }
      walkMoney({ walk: this.walkData.walk }).then((res) => {
        if (res.data.code === 0) {
          this.signedData = res.data.data
          console.log(this.signedData, 'this.signedData')
          this.signedData2 = res.data.data.goldReceiveRecord
          this.show = true

          // this.signWalk()
        }
      })
    },
    sht() {
      this.show = !this.show
      this.signWalk()
    },
    padlock() {
      this.show = false
      this.signWalk()
    },
    maskGift() {
      console.log('神秘礼包')
    },
  },
}
</script>

<style lang="less" scoped>
.Ge-happy {
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#7b99f7, #2e62f5);
  border-radius: 999px;
  margin-top: 15px;
  color: white;
  box-shadow: 0 0 5px 1px #7b99f7;
}
.Ge-box {
  margin-top: 15px;
}
.Ge-happy:active {
  background: linear-gradient(#2e62f5, #2e62f5);
}
.Ge-cancel {
  text-align: center;
  transform: translateY(35px);
}
.mask {
  z-index: 1000 !important;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(22, 22, 22, 0.7);
  .block {
    //   padding-top: 14px;
    height: 7.027rem;
    width: 6.4865rem;
    .mod3 {
      height: 7.027rem;
      border-radius: 0.3243rem;
      background-color: rgba(255, 255, 255, 1);
      width: 6.4865rem;
    }

    .group1 {
      width: 5.4054rem;
      height: 6.1081rem;
      margin: 0.3784rem 0 0 0.5405rem;
    }

    .word2 {
      // background: rgb(212, 209, 209);
      width: 1.7297rem;
      height: 0.5946rem;
      display: block;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 0.4324rem;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 0.5946rem;
      text-align: left;
      margin-left: 1.8378rem;
    }

    .pic6 {
      width: 2.8108rem;
      height: 2.5405rem;
      margin: 0.2703rem 0 0 1.4595rem;
    }
    .img4 {
      width: 2.5405rem;
      height: 2.5405rem;
      margin: 0.2703rem 0 0 1.4324rem;
      border: 1px solid red;
    }

    .txt3 {
      width: 2.7297rem;
      height: 0.3784rem;
      display: block;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 0.3784rem;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 0.3784rem;
      text-align: left;
      margin: 0.2703rem 0 0 1.3514rem;
    }

    .layer2 {
      width: 4.027rem;
      height: 0.3243rem;
      overflow-wrap: break-word;
      text-align: left;
      white-space: nowrap;
      font-size: 0;
      margin: 0.2703rem 0 0 0.7027rem;
    }

    .word3 {
      font-size: 0.3243rem;
      color: rgba(102, 102, 102, 1);
      line-height: 0.3243rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .word4 {
      font-size: 0.3243rem;
      color: rgba(34, 117, 245, 1);
      line-height: 0.3243rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .info4 {
      font-size: 0.3243rem;
      color: rgba(102, 102, 102, 1);
      line-height: 0.3243rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .layer3 {
      height: 0.9189rem;
      border-radius: 0.4595rem;
      box-shadow: 0px 1px 4px 0px rgba(81, 126, 253, 1);
      background: url(./img/backgroundBlue.png) -4px -3px no-repeat;
      margin-top: 0.5405rem;
      width: 5.4054rem;
    }

    .txt4 {
      width: 1.5135rem;
      height: 0.5405rem;
      display: block;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 0.3784rem;
      white-space: nowrap;
      line-height: 0.8649rem;
      text-align: left;
      margin: 0px 0 0 1.9459rem;
    }
  }
}
.box {
  height: 13.5135rem;
  background: linear-gradient(-180deg, #7dedff, #4abeff);
  position: relative;
  width: 100vw;
  box-sizing: border-box;
  /deep/ .van-nav-bar {
    background-color: #78e8ff;
    z-index: 9999999;
    .van-nav-bar__left .van-icon-arrow-left,
    .van-nav-bar__title {
      color: white;
    }
  }
  .contents {
    position: relative;
    .outer5 {
      z-index: 1;
      height: 0.8108rem;
      border-radius: 0.4054rem;
      background-color: rgba(91, 159, 255, 1);
      width: 2.8378rem;
      position: absolute;
      left: 0.2703rem;
      top: 0.5405rem;
      .main2 {
        padding-right: 0.2703rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .word4 {
          width: 0.973rem;
          display: inline-block;
          height: 0.4054rem;
          color: rgba(255, 255, 255, 1);
          font-size: 0.4054rem;
        }
        .layer2 {
          width: 0.1892rem;
          height: 0.3243rem;
          background: url(./img/rightArrow.png) 100% no-repeat;
          display: inline-block;
        }
      }
    }
    .bd2 {
      z-index: 25;
      position: absolute;
      left: -0.0811rem;
      top: 4.2703rem;
      width: 2rem;
      height: 3.9189rem;
      background: url(./img/leftCao.png) 3px 0px no-repeat;
    }
    .img2 {
      z-index: 22;
      position: absolute;
      left: 0;
      top: 1.7838rem;
      width: 2.7568rem;
      height: 6.7297rem;
    }
    .img1 {
      width: 3.1081rem;
      height: 5.1892rem;
      margin-top: 1.1622rem;
    }
    .img3 {
      z-index: 23;
      position: absolute;
      left: 8.3784rem;
      top: 4.2162rem;
      width: 1.7568rem;
      height: 4.1892rem;
    }
    .img4 {
      z-index: 136;
      position: absolute;
      left: 4.1351rem;
      top: 4.5676rem;
      width: 1.8378rem;
      height: 3.4324rem;
    }
    .pic1 {
      z-index: 24;
      position: absolute;
      left: 9.2162rem;
      top: 5.8378rem;
      width: 0.9189rem;
      height: 2.7838rem;
    }
    .pic2 {
      z-index: 5;
      position: absolute;
      left: 7.3514rem;
      top: 5.4054rem;
      width: 2.7838rem;
      height: 5.1892rem;
    }
    .text {
      position: absolute;
      left: 3.5946rem;
      top: 1.2973rem;
      .stp {
        font-size: 0.4865rem;
      }
      .steep {
        font-size: 0.9459rem;
      }
      .ratio {
        font-size: 0.4595rem;
      }
      div {
        text-align: center;
        color: #fff;
      }
    }
  }
  .main {
    z-index: 135;
    overflow: hidden;
    position: absolute;
    top: 8.973rem;
    // margin-top: -24px;
    // padding-top: 39px;
    width: 10.1351rem;
    // margin-left: 20px;
    // width: 100vw;
    height: 13.2432rem;
    background: url(./img/topYuan.png) 100% no-repeat;
    background-size: 100%;
    .main1 {
      z-index: 104;
      margin: 1.1351rem 0.4054rem 0 0.3243rem;
      height: 4.4595rem;
      border-radius: 0.2162rem;
      background-color: rgba(255, 255, 255, 1);
      width: 9.3243rem;
      position: relative;
      .bd4 {
        padding-top: 0.6486rem;
        width: 7.5405rem;
        height: 0.2703rem;
        margin-left: 0.9459rem;
        display: flex;
        justify-content: space-around;
        .txt1 {
          width: 0.5135rem;
          height: 0.2703rem;
          display: block;
          overflow-wrap: break-word;
          color: rgba(204, 204, 204, 1);
          font-size: 0.2703rem;
          white-space: nowrap;
          line-height: 0.2703rem;
          text-align: left;
        }
        .active {
          color: rgba(255, 172, 2, 1);
        }
        .ste {
          color: rgba(255, 172, 2, 1);
        }
      }
      .bd5 {
        // pos
        height: 0.2162rem;
        background-color: rgba(223, 235, 250, 1);
        margin-top: 0.4595rem;
        width: 9.3243rem;
        .outer6 {
          //   width: 105px;
          height: 0.2162rem;
          background-color: rgba(2, 102, 248, 1);
          position: relative;
          .label3 {
            z-index: 123;
            position: absolute;
            // left: 50px;
            top: -0.1351rem;
            width: 0.5405rem;
            height: 0.5405rem;
          }
        }
      }
      .bd6 {
        width: 7.7027rem;
        height: 0.4324rem;
        margin: 0.4054rem 0 0 0.8108rem;
        display: flex;
        justify-content: space-around;
        .txt3 {
          width: 0.7838rem;
          height: 0.4324rem;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 0.2973rem;
          white-space: nowrap;
          line-height: 0.4324rem;
          text-align: left;
        }

        .txt4 {
          width: 0.7838rem;
          height: 0.4324rem;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 0.2973rem;
          white-space: nowrap;
          line-height: 0.4324rem;
          text-align: left;
        }

        .txt5 {
          width: 0.7838rem;
          height: 0.4324rem;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 0.2973rem;
          white-space: nowrap;
          line-height: 0.4324rem;
          text-align: left;
        }

        .info4 {
          width: 0.7838rem;
          height: 0.4324rem;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 0.2973rem;
          white-space: nowrap;
          line-height: 0.4324rem;
          text-align: left;
        }

        .word7 {
          width: 0.7297rem;
          height: 0.4324rem;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 0.2973rem;
          white-space: nowrap;
          line-height: 0.4324rem;
          text-align: left;
        }
      }
      .bd7 {
        width: 7.5676rem;
        height: 1.1892rem;
        border-radius: 0.5946rem;
        background-color: rgba(10, 86, 196, 1);
        margin: 0.5676rem 0 0 0.8649rem;
      }
      .bd71 {
        width: 7.5676rem;
        height: 1.3243rem;
        border-radius: 0.5946rem;
        background-color: rgb(160, 162, 163);
        // background: red;
        margin: 0.5135rem 0 0 0.8649rem;
        position: relative;
        // z-index: 999;
        .bd71-bg {
          position: absolute;
          margin-bottom: -3px;
          width: 7.5676rem;
          height: 1.1892rem;
          // background: red;
          background-color: rgb(200, 203, 206);
          border-radius: 0.5946rem;
          position: absolute;
        }
      }

      .button {
        z-index: 113;
        height: 1.1892rem;
        border-radius: 0.5946rem;
        width: 7.5676rem;
        margin: auto;
        background: linear-gradient(#5b9cff, #0266f8);
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 5px 0px #0a56c4;
        .word8 {
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 0.3784rem;
          white-space: nowrap;
        }
      }

      .button1 {
        z-index: 113;
        height: 1.1892rem;
        border-radius: 0.5946rem;
        width: 7.5676rem;
        margin: auto;
        background: linear-gradient(#eeeeee, #dadada);
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 5px 0px #dadada;
        .word8 {
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 0.3784rem;
          white-space: nowrap;
        }
      }
      .section8 {
        z-index: 105;
        position: absolute;
        left: 6.5405rem;
        top: -0.2973rem;
        width: 0.7568rem;
        height: 0.7568rem;
        background: url('../../assets/img/Invitation/金币@2x.png') no-repeat;
        background-size: 28px 28px;
      }
    }
    .main2 {
      height: 7.0811rem;
      border-radius: 0.2162rem;
      background-color: rgba(255, 255, 255, 1);
      margin-top: 0.2703rem;
      margin-left: 0.3514rem;
      width: 9.3243rem;
      .text {
        width: 8.4865rem;
        // height: 233px;
        padding: 0.4054rem 0 0 0.4054rem;
        .word9 {
          width: 2.6757rem;
          height: 0.5676rem;
          display: block;
          overflow-wrap: break-word;
          color: rgba(51, 51, 51, 1);
          font-size: 0.4054rem;
          white-space: nowrap;
          line-height: 0.5676rem;
          text-align: left;
        }
        .outer7 {
          width: 5.4865rem;
          height: 0.4865rem;
          overflow-wrap: break-word;
          text-align: left;
          white-space: nowrap;
          margin-top: 0.1351rem;
          font-size: 0;
          .txt6 {
            font-size: 0.3514rem;
            color: rgba(102, 102, 102, 1);
            line-height: 0.4865rem;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .word10 {
            font-size: 0.3514rem;
            color: rgba(255, 140, 44, 1);
            line-height: 0.4865rem;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .word11 {
            font-size: 0.3514rem;
            color: rgba(102, 102, 102, 1);
            line-height: 0.4865rem;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .sign-desk {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 8.4865rem;
        height: 2.1622rem;
        // margin-top: 19px;
        padding-left: 0.4054rem;
        .section2 {
          height: 2.1622rem;
          border-radius: 0.1622rem;
          //   background-color: rgba(34, 117, 245, 1);
          background-color: rgba(233, 243, 255, 1);
          margin-top: 0.2703rem;
          width: 1.9189rem;
          .mod1 {
            color: rgba(22, 91, 198, 1);
            width: 1.9189rem;
            height: 2.027rem;
            .layer3 {
              height: 0.5405rem;
              border-radius: 0.1622rem 0.1622rem 0 0;
              //   background-color: rgba(78, 155, 255, 1);
              background-color: rgba(194, 221, 255, 1);
              width: 1.9189rem;
              transform: translateY(-3px);
              .word12 {
                width: 0.7297rem;
                height: 0.2973rem;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 0.8);
                font-size: 0.2973rem;
                white-space: nowrap;
                line-height: 0.5405rem;
                text-align: left;
                margin: 0.1351rem 0 0 0.5946rem;
              }
            }
            .active2 {
              background-color: rgb(158, 198, 248);
            }
            .layer4 {
              width: 0.5946rem;
              height: 0.5946rem;
              //   background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng87ab197a127b6fee458549ce40b675f161c2ec4c0f6fc244934bb4fbfc4433e7) -4px -2px
              //     no-repeat;
              margin: 0.2162rem 0 0 0.6757rem;
              .none {
                display: none;
              }
              .imgnone {
                display: none;
              }
            }
            .layer5 {
              height: 0.4324rem;
              border-radius: 0.2162rem;

              width: 1.2162rem;
              margin: 0.2432rem 0 0 0.3514rem;
              .word13 {
                // width: 31px;
                // height: 11px;
                text-align: center;
                display: block;
                // overflow-wrap: break-word;
                // color: rgba(22, 91, 198, 1);
                font-size: 0.2973rem;
                white-space: nowrap;
                padding-bottom: 0.2162rem;
                // line-height: 11px;
                // text-align: left;
                // margin: 3px 0 0 7px;
              }
            }
          }
          .active {
            background-color: #0a56c4;
            color: #fff;
            border-radius: 0.2162rem;
          }
        }
        .mod3 {
          width: 4.1081rem;
          height: 2.1351rem;
          background-color: rgba(233, 243, 255, 1);
          transform: translateY(12px);
          border-radius: 0.1622rem 0.1622rem 0 0;
          border-radius: 0.1316rem;
          .mod4 {
            height: 0.5405rem;
            border-radius: 0.1622rem 0.1622rem 0 0;
            background-color: rgba(194, 221, 255, 1);
            width: 4.1081rem;
            margin-right: 5px;
            // margin-top: 15px;
            transform: translateY(-6px);
            .word20 {
              width: 0.7838rem;
              height: 0.2973rem;
              display: block;
              overflow-wrap: break-word;
              color: rgba(251, 252, 253, 0.8);
              font-size: 0.2973rem;
              white-space: nowrap;
              line-height: 0.5405rem;
              text-align: left;
              margin: 0.1351rem 0 0 1.6757rem;
            }
          }
          .mod5 {
            width: 3.027rem;
            height: 1.3784rem;
            margin: 0.2162rem 0 0 0.5135rem;
            display: flex;
            justify-content: space-around;
            // align-items: center;
            .info7 {
              width: 1.1892rem;
              height: 0.2973rem;
              display: block;
              overflow-wrap: break-word;
              color: rgba(65, 138, 247, 1);
              font-size: 0.2973rem;
              white-space: nowrap;
              line-height: 0.2973rem;
              text-align: left;
              margin-top: 0.4595rem;
            }
            .img5 {
              width: 1.2703rem;
              height: 51px;
            }
          }
        }
      }
    }
  }
}
</style>
